/* Light */
@font-face {
  font-family: "Lato";
  src: url("./core/assets/fonts/Lato-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("./core/assets/fonts/Lato-LightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

/* Regular */
@font-face {
  font-family: "Lato";
  src: url("./core/assets/fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("./core/assets/fonts/Lato-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/* Bold */
@font-face {
  font-family: "Lato";
  src: url("./core/assets/fonts/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("./core/assets/fonts/Lato-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

/* Black */
@font-face {
  font-family: "Lato";
  src: url("./core/assets/fonts/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("./core/assets/fonts/Lato-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@import "shepherd.js/dist/css/shepherd.css";

/* Custom scrollbar and selection */
::selection {
  background: #00efd1;
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
  background: rgba(0, 172, 234, 0.1);
}

::-webkit-scrollbar-thumb {
  background: #00acea;
  border-radius: 0.6rem;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #00acea rgba(0, 172, 234, 0.1);
}

/* Global override */
* {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: auto;
  min-width: 360px;

  /*These styles have !important because of MatPopover in the Header.js*/
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* Beta version sign */
/*body:after {*/
/*  content: "beta";*/
/*  position: fixed;*/
/*  width: 80px;*/
/*  height: 25px;*/
/*  background: #ee8e4a;*/
/*  top: 7px;*/
/*  left: -20px;*/
/*  text-align: center;*/
/*  font-size: 13px;*/
/*  font-family: sans-serif;*/
/*  text-transform: uppercase;*/
/*  font-weight: bold;*/
/*  color: #fff;*/
/*  line-height: 27px;*/
/*  transform: rotate(-45deg);*/
/*}*/

#root {
  height: calc(100vh - 13.5rem);
}

.ace_editor,
.ace_editor * {
  font-family: Courier, monospace !important;
  line-height: 2.5rem;
}

/* Panel styles */
.panel-heading {
  background-image: none !important;
}

.panel-group {
  margin-bottom: 0;
}

/* Typewriter styles */
.Typewriter__wrapper {
  font-size: 2rem;
  color: #54e346;
}

.Typewriter__cursor {
  font-size: 2rem;
  color: #54e346;
}

.margin-spacing-right {
  margin-right: 1rem;
}

.slide-right {
  animation: 1s slide-right 0.2s forwards;
  transform: translateX(-100%);
}

@keyframes slide-right {
  to {
    transform: translateX(0);
  }
}

.slide-left {
  animation: 1s slide-left 0.2s forwards;
  transform: translateX(+100%);
}

@keyframes slide-left {
  to {
    transform: translateX(0);
  }
}

a {
  text-decoration: none !important;
}

.accordion {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.unselectable {
  user-select: none;
}

/* editor override */
.tab-height.react-tabs__tab-panel--selected {
  height: 64vh;
  overflow: auto;
}

/* slick slider override */
.slick-slide img {
  opacity: 0.5;
  transform: scale(0.75);
}
.slick-slide.slick-current img {
  opacity: 1;
  transform: scale(1.1);
}

.overflow-auto {
  overflow: auto !important;
}

.react-quiz-container {
  margin: auto !important;
  background: white;
  padding: 2rem;
  width: 600px;

  @media (max-width: 620px) {
    width: 375px !important;
    padding: 1rem;
  }
}

.alice-carousel__next-btn-item {
  font-size: 2rem;
  font-weight: bold;
}

.alice-carousel__prev-btn-item {
  font-size: 2rem;
  font-weight: bold;
}

.shepherd-title {
  display: contents;
}

.shepherd-header {
  justify-content: space-between;
  align-items: baseline;
}

.shepherd-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
